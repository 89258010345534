import { realTime } from '../../firebase';
export const getUrlPermisos = async (idproyecto) => {
    try {
        var proyectosRef = await realTime.ref("/apps/mlp/config/proyectos").orderByChild("id_proyecto").equalTo(idproyecto).once("value").then();
        var proyectos = proyectosRef.val();

        console.log(Object.values(proyectos)[0].url_data_studio)
        if(Object.values(proyectos)[0]?.url_data_studio){
            
            return Object.values(proyectos)[0].url_data_studio;
        }else{
            
            return "https://datastudio.google.com/embed/reporting/1YDxLtdWznVORspcLi6NFj-9xCtnkB2up/page/BTN5";
        }
        // if()
        return Object.values(proyectos)[0].url_data_studio;
    } catch (error) {
        return "https://datastudio.google.com/embed/reporting/1YDxLtdWznVORspcLi6NFj-9xCtnkB2up/page/BTN5";
    }


}