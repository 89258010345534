import React, { useState } from 'react';
import Page from './page';
import { Formik } from 'formik';
import * as Yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import { accionCloseContenido } from '../redux/actionsEjemplo';
import { useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';


// import { uploadFileToDrive } from '../../../services/fileUpload';
// import { updateCartas } from '../../../services/database/cartas';

export default function CerrarCarta(props) {

    const { open, bundle, proyecto } = useSelector(state => ({
        open: state.storeEjemplo.openCerrarContenido,
        bundle: state.storeEjemplo.bundle,

        proyecto: state.sesion.proyecto
    }));

    const dispatch = useDispatch();
    const notisnack = useSnackbar();
    const [formik, setFormik] = useState(null);
    const validationSchema = Yup.object({});
    const values = {
        fecha_cierre: new Date(),
        adjunto: null,
        comentarios: "",
        estado: "Cerrado"
    };

    const handleEnterDialog = async () => {
        console.log("handleEnterDialog");
        //formik.resetForm(values);
    }

    const handleChange = (e, { name, value }) => {
        //formik.setFieldValue(name, value);
        e.persist();
    };

    const handleOnSubmit = async (values, formikBag) => {
        const { handleSubmit } = props;
        //alert(JSON.stringify(component.getValues()));
        const key = notisnack.enqueueSnackbar("Guardando Cambios...", {
            persist: true,
            anchorOrigin: {
                horizontal: "right",
                vertical: "top"
            }
        });
        console.log(values);

        setTimeout(() => {
            notisnack.closeSnackbar(key);
            dispatch(accionCloseContenido());
        }, 3000);
    }

    const handleClose = () => {
        dispatch(accionCloseContenido());
    }



    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleOnSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                render={props =>
                    <Page
                        open={open}
                        titulo="Cerrar Carta"
                        handleEnterDialog={handleEnterDialog}
                        handleClose={handleClose}
                        validationSchema={validationSchema}
                        {...props}
                    />}
            />
        </React.Fragment>
    );
}