import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from "./page";
import { getUrlPermisos } from "../../services/database/datastudio"

export default function Dashboard(props) {

    const [url, setUrl] = useState("");
    const { proyecto, contrato } = useSelector(state => ({
        contrato: state.sesion.contrato,
        proyecto: state.sesion.proyecto,
    }));
    const loadData = async (id) => {
        var url = await getUrlPermisos(id);
        console.log(url);
        setUrl(url);
    }

    useEffect(() => {
        console.log("===============");
        console.log(proyecto);
        if (proyecto) {
            loadData(proyecto._id)
        }
    }, [proyecto]);



    return (
        <Page
            url={url}
        />
    );
}
