import React, { Fragment } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { cerrarSesion } from '../../services/auth'
import { ExpandMore } from '@material-ui/icons';

import './style.css';


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    grow: {
        flexGrow: 1,
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 3,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },

    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    contenedorUser: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: '20px',
    },
    titleNoMargin: {
        margin: 0,
        textAlign: 'end'
    },
    titleBold: {
        fontWeight: 'bold',
    },
    hide: {
        display: 'none',
    },
    button: {
        zIndex: theme.zIndex.drawer + 2,
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
    homeLink: {
        color: "white",
        textDecoration: "none"
      }
}));




function Page(props) {
    const {
        tituloAppBar,
        nombreUsuario,
        urlImagenUsuario,
        cantidadMensajes,
        cantidadNotificaciones,
        onClickMenuLateral,
        isAutheticadoUser,
        handleOpenSelector,
        contrato,
        proyecto
    } = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);


    const mobileMenuId = 'primary-search-account-menu-mobile';
    const menuId = 'primary-search-account-menu';
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);



    function handleProfileMenuOpen(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null);
    }
    function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget);
    }
    function handleMenuClose() {
        setAnchorEl(null);
        handleMobileMenuClose();
    }
    async function handleCerrarSession() {
        setAnchorEl(null);
        await cerrarSesion();
        handleMobileMenuClose();
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Perfíl</MenuItem>
            <MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
        </Menu>
    );


    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}

        >
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="Account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <Avatar alt="Remy Sharp" src={urlImagenUsuario} className={classes.bigAvatar} />
                </IconButton>
                <p>Perfil</p>
            </MenuItem>
            <MenuItem >
                <IconButton aria-label="Show 4 new mails" color="inherit">
                    <Badge badgeContent={cantidadMensajes} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Mensajes</p>
            </MenuItem>
            <MenuItem >
                <IconButton aria-label="Show 11 new notifications" color="inherit">
                    <Badge badgeContent={cantidadNotificaciones} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notificaciones</p>
            </MenuItem>

        </Menu>
    );

    const handleClickMenuLateral = () => {
        onClickMenuLateral();
    };

    return (
        <Fragment>
            <div className={classes.root}>
                <AppBar position="fixed" className={classes.appBar}  >
                    <Toolbar >
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={handleClickMenuLateral}>
                            <MenuIcon />
                        </IconButton>
                        <Typography className={classes.title} variant="h6" color="inherit">
                            <Link className={classes.homeLink} to="/">{tituloAppBar}</Link>
                        </Typography>
                        <Button color="primary" className={"selectorContratos"} variant="outlined" aria-label="Selector Contrato" onClick={handleOpenSelector}>
                            {(contrato && proyecto && proyecto.nombre && contrato.codigo) ? contrato.codigo+ "-"+ proyecto.nombre : "Contrato"} <ExpandMore />
                        </Button>
                        <div className={classes.grow} />
                        <div className={clsx({ [classes.hide]: isAutheticadoUser === "false", }, classes.sectionDesktop)}>
                            {/* <IconButton aria-label="Show 4 new mails" color="inherit" className={clsx({ [classes.hide]: showIconMensajes === "true", })} onClick={handleClickMensajes}>
                                <Badge badgeContent={cantidadMensajes} color="secondary">
                                    <MailIcon />
                                </Badge>
                            </IconButton> */}
                            {/* <IconButton aria-label="Show 17 new notifications" color="inherit" className={clsx({ [classes.hide]: showIconNotificaciones === "true", })} onClick={handleClickNotificaciones}>
                                <Badge badgeContent={cantidadNotificaciones} color="secondary">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}
                            <Grid
                                container
                                direction="column">
                                <div className={classes.contenedorUser}>
                                    <Grid item xs>
                                        <Typography className={classes.titleNoMargin} variant="body2" gutterBottom color="inherit">
                                            Bienvenido
                                        </Typography>
                                        <div style={{ whiteSpace: 'nowrap' }}>
                                            <Box component="div" textOverflow="ellipsis" overflow="hidden">
                                                <Typography className={classes.titleBold} variant="body2" color="inherit">
                                                    {nombreUsuario}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>

                            <IconButton
                                edge="end"
                                aria-label="Account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit">

                                <Avatar alt="Remy Sharp" src={urlImagenUsuario} className={classes.bigAvatar} />
                            </IconButton>
                        </div>
                        <div className={clsx(classes.sectionMobile, { [classes.hide]: isAutheticadoUser === "false", })}>
                            <IconButton
                                aria-label="Show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon />
                            </IconButton>
                        </div>
                        <Button color="inherit" className={clsx({ [classes.hide]: isAutheticadoUser === "true", })} >Login</Button>

                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
        </Fragment>
    );
}

export default Page;