import { realTime } from '../../firebase';
export const getInspecciones = async (id_proyecto) => {
    return new Promise((resolve, reject) => {
        realTime.ref("apps/mlp/registros").orderByChild("id_proyecto").equalTo(id_proyecto).once("value", async snapshot => {
            console.log(snapshot.val());
            const registros = snapshot.val();
            if (registros) {
                const registrosList = Object.keys(registros).map((key, index) => ({
                    ...registros[key],
                    uid: key,
                    count: (index + 1)
                }))
                const registrosListFiltrados = registrosList.filter((item)=>{
                    if(item.estado !== "duplicado"){
                        return item;
                    }
                })
                
                resolve(registrosListFiltrados);
            }
            resolve([]);
    
        });
    });

    
}

export const addidInspecciones = async () => {
    realTime.ref("apps/mlp").once("value", async snapshot => {

        const registros = snapshot.val().registros;
        const proyectosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: (index + 1)
        }))
        let cambio = false;
        for (let i = 0; i < proyectosList.length; i++) {
            let confProyecto = snapshot.val().config.proyectos;
            const proyectos = Object.keys(confProyecto).map(i => confProyecto[i]);
            let superIntendecia = proyectos.find(e => e.nombre_proyecto === proyectosList[i].proyecto);
            var updates = {};
            if (typeof superIntendecia !== "undefined") {

                console.log(proyectosList[i]);
                updates[proyectosList[i].uid + '/id_proyecto'] = superIntendecia.id_proyecto;
                await realTime.ref("apps/mlp/registros/").update(updates);
                // if (proyectosList[i].proyecto === "Nombre prueba ") {
                //     var updates = {};
                //     console.log("repete");
                //     console.log(proyectosList[i]);
                // }

            }else{
                console.error(proyectosList[i]);
                if(proyectosList[i].proyecto === "4540003955-Cambio de Revestimiento Stock Pile y Planta Filtrado Puerto "){
                    updates[proyectosList[i].uid + '/proyecto'] = "4540003955-Cambio de Revestimiento Stock Pile y Planta Filtrado Puerto";
                }
                let superIntendecia = proyectos.find(e => e.nombre_proyecto === "4540003955-Cambio de Revestimiento Stock Pile y Planta Filtrado Puerto");
                updates[proyectosList[i].uid + '/id_proyecto'] = superIntendecia.id_proyecto;
                await realTime.ref("apps/mlp/registros/").update(updates);
                
            }
            
            

            

           


        }
        console.log("fin");
        return null;
    });

}

export const getInspeccionesPorNombre = async (codigo, proyecto) => {

    // realTime.ref("apps/mlp/registros").orderByChild("proyecto").equalTo(codigo+"-"+proyecto).once("value",async snapshot =>{
    //     console.log(snapshot.val());
    //     return snapshot.val();
    // });
    console.log(codigo + "-" + proyecto);
    let nombreProyecto = codigo + "-" + proyecto;
    //realTime.ref("apps/mlp/registros").orderByChild("proyecto").startAt(codigo).once("value", async snapshot => {
    realTime.ref("apps/mlp/registros").orderByChild("proyecto").equalTo(nombreProyecto).once("value", async snapshot => {
        console.log(snapshot.val());
        const registros = snapshot.val();
        if (registros) {
            const registrosList = Object.keys(registros).map((key, index) => ({
                ...registros[key],
                uid: key,
                count: (index + 1)
            }))
            console.log(registrosList);
            return snapshot.val();
        }
        return []

    });



    // realTime.ref("apps/mlp").orderByValue().once("value", async snapshot => {
    //     const registros = snapshot.val().registros;

    //     if (registros) {
    //         const registrosList = Object.keys(registros).map((key, index) => ({
    //             ...registros[key],
    //             uid: key,
    //             count: (index + 1)
    //         }))


    //         var newArray = [];

    //         for (var i = registrosList.length - 1; i >= 0; i--) {
    //             if (registrosList[i].estado !== "duplicado") {
    //                 let confProyecto = snapshot.val().config.proyectos;
    //                 const peopleArray = Object.keys(confProyecto).map(i => confProyecto[i])
    //                 let superIntendecia = peopleArray.find(e => e.nombre_proyecto === registrosList[i].proyecto);
    //                 registrosList[i]["si"] = superIntendecia?.si
    //                 newArray.push(registrosList[i]);

    //             }
    //         }

    //         var finalIsnpeccion = newArray;

    //         return finalIsnpeccion;


    //     } else {
    //         console.log("data empty");
    //         return [];
    //     }
    // })
}