import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Page from './page';




class ListadoContratos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        };

        
    }



    componentDidMount() {
      
    }

   
    render() {

        window.onload = window.onfocus = function () {
            console.log("onload")
            
        }
        window.onblur = function () {
            console.log("onblur")
        }
 
        

        return (
            <Page
                
            />
        );
    }
}

const mapStateToProps = state => ({

});

export default withRouter(
    connect(mapStateToProps)(ListadoContratos)
);