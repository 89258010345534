import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from './page';

export default function Dashboard(props) {

    const { open, bundle, proyecto } = useSelector(state => ({
        open: state.storeEjemplo.openCerrarContenido,
        bundle: state.storeEjemplo.bundle,

        proyecto: state.sesion.proyecto
    }));
    const {
        total,
        totalCierre,
        totalPendienteCierre
    }=props;

    const dispatch = useDispatch();
    const notisnack = useSnackbar();

    return (
        <Page
        total={total}
        totalCierre={totalCierre}
        totalPendienteCierre={totalPendienteCierre}
        />
    );
}
