import React, { useEffect } from 'react';
import Page from './page';
import { auth } from '../../services/firebase';
import { useHistory } from 'react-router-dom';
import * as URLS from '../../constants/urls';
import { useSelector } from 'react-redux';
// const baseUrl = 'https://api-usuarios.cydocs.cl/login';
// const baseUrl = 'https://us-central1-cydingenieria-proyectos.cloudfunctions.net/widgets';

export default function LoginPage(props) {

    const history = useHistory();

    const { login } = useSelector(state => ({
        login: state.login
    }));

    const getQueryVariable = function (variable) {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }

    const tryLogin = async () => {
        // const { login } = props;
        let currenToken = getQueryVariable("csrfToken");
        if (!login) history.push("");
        if (!currenToken) {
            currenToken = localStorage.getItem("csrfToken");
        }
        localStorage.setItem("csrfToken", currenToken);
        if (currenToken && currenToken !== "null" && currenToken !== "undefined") {
            localStorage.setItem("contrato", "");
            localStorage.setItem("proyecto", "");
            await fetch(`${URLS.REDIRECT_BASE}/verifySessionCookie?_csrf=${currenToken}`,
                {
                    method: 'post',
                    credentials: 'include',
                })
                .then(response => response.json())
                .then(jsonResponse => auth.signInWithCustomToken(jsonResponse.token))
                .catch(error => {
                    console.log(error);
                    window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split("?")[0]}`;
                });
        } else {
            window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split("?")[0]}`;
        }
    }

    useEffect(() => {
        tryLogin();
    }, []);

    return (
        <Page />
    );
}